export const str2datetime = (strDate, strTime) => {
	const val = Date.parse(strDate + 'T' + (strTime || "00:00"));
    console.log(val);
	return val;
};

export const getNow = (offset) => {
    const localTime = new Date();
    const utcTime = localTime.getTime() + (localTime.getTimezoneOffset() * 60000);
    const offsetTime = new Date(utcTime + (3600000 * offset));
	return offsetTime;
};

export const getDateStrWitoutTime = (datetime, dateFormat = (year, month, day) => `${year}-${month}-${day}` ) => {
    const day = String(datetime.getDate()).padStart(2, '0'); 
    const month = String(datetime.getMonth() + 1).padStart(2, '0'); 
    const year = datetime.getFullYear();

    return dateFormat(year, month, day)
}

export const getDateWithDayOffset = (daysOffset = 0, monthAdjustment = false) => {
    const now = getNow(2);

    const dateWithOffset = new Date(now);
    dateWithOffset.setDate(now.getDate() - daysOffset);
    if (monthAdjustment){
        dateWithOffset.setDate(1)
    }

    return getDateStrWitoutTime(dateWithOffset)
}
