import { useTranslate } from 'react-admin';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { DATE_TIME_FORMAT, DATE_FORMAT, format, parseISO } from '../../../components/_helpers/dateTime';
import { isNullUndEmpty }from "../../../utils/general"
import { toPrice } from '../../../components/fields/PriceField';

const prepareDatetime = (datetime) =>{
    return format(parseISO(datetime), DATE_TIME_FORMAT);
}  

const prepareDate = (datetime) =>{
    return format(parseISO(datetime), DATE_FORMAT);
}  

const prepareTime = (datetime) =>{
    if (! isNullUndEmpty(datetime)){
        return new Date(datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return datetime
}  

export const TisbankenPlaningDatagrid = ({tisbankenPlaningData}) => {
    const translate = useTranslate()

    const totalHours = tisbankenPlaningData.reduce((sum, item) => sum + item.total_hours, 0);
    const totalPauseHours = tisbankenPlaningData.reduce((sum, item) => sum + item.total_pause_hours, 0);
    const totalSalary = tisbankenPlaningData.reduce((sum, item) => sum + item.salary, 0);

    return (
        <Table  size="small" aria-label="a dense table">
            <TableHead>
            <TableRow style={{backgroundColor:"#d8e6db"}}>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.date')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.employee_id')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_from')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_to')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_break1_from')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_break1_to')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_break2_from')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_break2_to')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.department_code')}</TableCell>
                <TableCell align="right">{translate('resources.tisbanken-planing-data.fields.total_hours')}</TableCell>
                <TableCell align="right">{translate('resources.tisbanken-planing-data.fields.total_pause_hours')}</TableCell>
                <TableCell align="right">{translate('resources.tisbanken-planing-data.fields.salary')}</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {tisbankenPlaningData.map((row) => (
                <TableRow key={row.name}>
                <TableCell component="th" scope="row">{prepareDate(row.date)}</TableCell>
                <TableCell component="th" scope="row">{row.employee_id}</TableCell>
                <TableCell component="th" scope="row">{prepareDatetime(row.clock_from_with_ttz)}</TableCell>
                <TableCell component="th" scope="row">{prepareDatetime(row.clock_to_with_ttz)}</TableCell>
                <TableCell component="th" scope="row">{prepareTime(row.clock_break1_from_with_ttz)}</TableCell>
                <TableCell component="th" scope="row">{prepareTime(row.clock_break1_to_with_ttz)}</TableCell>
                <TableCell component="th" scope="row">{prepareTime(row.clock_break2_from_with_ttz)}</TableCell>
                <TableCell component="th" scope="row">{prepareTime(row.clock_break2_to_with_ttz)}</TableCell>
                <TableCell component="th" scope="row">{row.department_code}</TableCell>
                <TableCell component="th" scope="row" align="right">{row.total_hours}</TableCell>
                <TableCell component="th" scope="row" align="right">{row.total_pause_hours}</TableCell>
                <TableCell component="th" scope="row" align="right">{toPrice(row.salary)}</TableCell>
                </TableRow>
            ))}
            <TableRow style={{fontWeight: "bold", backgroundColor: "#dadfed"}}>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}}>Total</TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}} align="right">{totalHours.toFixed(2)}</TableCell>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}} align="right">{totalPauseHours.toFixed(2)}</TableCell>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}} align="right">{toPrice(totalSalary.toFixed(2))}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}