import React, { useMemo } from 'react';
import { useTranslate, useListContext } from 'react-admin';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";

import Box from '@material-ui/core/Box';

import { OrdersDatagrid } from './OrdersDataGrid';
import { LaborCostDatagrid } from './LaborCostDataGrid';

Chart.register(CategoryScale);


const prepareChartData = (data, revenueTooltip, expensesTooltip) => {

    return {
        labels: Object.values(data).map(row => row.grouping_period),
        datasets: [
            {
                label: revenueTooltip,
                data: Object.values(data).map(row => row.revenue),
                backgroundColor: [
                  'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                  'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1
            } ,
            {
                label: expensesTooltip,
                data:  Object.values(data).map(row => row.expenses),
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1
            }
        ]
    }
}


export const RevenueAndExpensesChart = props => {
    const translate = useTranslate()
    const { filterValues } = useListContext();

    const isPeriodOneDay = filterValues['date_from'] === filterValues['date_to'];
    
    const revenueTooltip = translate("resources.financial-statements.barchart.revenue.label")
    const expensesTooltip = translate("resources.financial-statements.barchart.labor-cost.label")

    const chartData = React.useMemo(() => {
          return prepareChartData((props && props.data && Object.keys(props.data).length !== 0) ? props.data[1]['financial_statement_data'] : {}, revenueTooltip, expensesTooltip)
    },[props.data])

    const orders = React.useMemo(() => {
        return (props && props.data && Object.keys(props.data).length !== 0) ? props.data[1]['orders'] : {}
    },[props.data])

    const laborCost = React.useMemo(() => {
        return (props && props.data && Object.keys(props.data).length !== 0) ? props.data[1]['labor_cost'] : {}
    },[props.data])

    if (Object.keys(props.data).length === 0){
      return null
    }  

    return (
          <>
          <Box maxHeight="750px" display={"flex"} justifyContent="center">
              <Bar
                  data={chartData}
                  options={{
                  plugins: {
                      title: {
                      display: true,
                      text: translate("resources.financial-statements.label")
                      },
                      legend: {
                        display: false
                      }
                  }
                  }}
              />
          </Box>
          {isPeriodOneDay && <>
              <div align='center'><h1>Orders</h1></div>
              <OrdersDatagrid orders={orders}/>
              <div align='center'><h1>Labor cost</h1></div>
              <LaborCostDatagrid laborCost={laborCost}/>
           </>}
          </>
    )
};
