import React from "react";
import { ReferenceInput, SelectInput, TextInput, AutocompleteInput, NumberInput, maxValue, minValue, required  } from "react-admin";
import { useForm, useFormState } from 'react-final-form'

import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";
import { FormGroupGrid , GridForm, GridInput} from "../../../components/GridForm";
import { DateTimeInput } from "../../../components/inputs";

import { DirectionType } from "../../../entities/DirectionType";
import { CountryCameraCodes } from "../../../entities/CameraCountryCodes";
import { OSLOCameraId, WSCCameraId, OSLOIp, WSCIp } from "../../../entities/CameraIp";
import { CameraTerminal } from "../../../entities/CameraTerminal";

import { getNow } from "../../../utils/datetime";

export const CreateVehicleAnprForm = props => {
    const form = useForm();
	const { values } = useFormState();
    const [cameraId, setCameraId] = React.useState(OSLOCameraId);
    const [cameraIp, setCameraIp] = React.useState(OSLOIp[92]);

    React.useEffect(() => {
        form.change('event_date_time', getNow(2).toISOString());
    }, []);
    

    const handleTerminalChange = (e) => {
        if (e.target.value == CameraTerminal.OSLO) {
            setCameraId(OSLOCameraId);
            setCameraIp(OSLOIp[OSLOCameraId.OSLO_92]);
            form.change('camera_ip_address', OSLOIp[OSLOCameraId.OSLO_92][0].id);
            form.change('camera_id', OSLOCameraId.OSLO_92);

        } else {
            setCameraId(WSCCameraId);
            setCameraIp(WSCIp[WSCCameraId.WSC_84]);
            form.change('camera_ip_address', WSCIp[WSCCameraId.WSC_84][0].id);
            form.change('camera_id', WSCCameraId.WSC_84);
        }
    }

    const handleCameraChange = (e) => {
        if (values.terminal_id == CameraTerminal.OSLO) {
            setCameraIp(OSLOIp[e.target.value]);
            form.change('camera_ip_address', OSLOIp[e.target.value][0].id);
        } else {
            setCameraIp(WSCIp[e.target.value]);
            form.change('camera_ip_address', WSCIp[e.target.value][0].id);
        }
    }

    return (
        <GridForm {...props}>
            <FormGroupGrid>
                <GridInput lg={1} sm={6}>
                    <ReferenceInput source="terminal_id" reference="terminal" onChange={handleTerminalChange} 
                        defaultValue={1} validate={[required()]}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </GridInput>

                <GridInput component={TextInput}source="vehicle_license_plate" lg={3} sm={6} validate={[required()]}/>
                
                <GridInput component={SelectEnumInput} source="vehicle_direction_type"  
                    enumName="vehicle_direction_type" enumObj={DirectionType} 
                    defaultValue={DirectionType.IN} lg={1} sm={6} validate={[required()]}/>

                <GridInput component={DateTimeInput} source="event_date_time" lg={2} sm={6} validate={[required()]}/>

                <GridInput component={AutocompleteInput} choices={CountryCameraCodes} source="vehicle_country" 
                    lg={5} sm={6} validate={[required()]} options={{ InputProps: {  size: "small" } }}/>
            </FormGroupGrid>

            <FormGroupGrid>
                <GridInput component={NumberInput}  validate={[
                    minValue(0),
                    maxValue(100),
                    required()
                ]} source="anpr_confidence_level" lg={1} sm={6} defaultValue={100} />

                <GridInput component={SelectEnumInput} enumObj={cameraId} enumName={"camera_id"} key={"camera_id"} onChange={handleCameraChange} 
                    source="camera_id" lg={3} sm={6} validate={[required()]} defaultValue={OSLOCameraId.OSLO_92}/>

                <GridInput component={SelectInput} choices={cameraIp} source="camera_ip_address"  
                    lg={3} sm={6} validate={[required()]} defaultValue={cameraIp[0].id}/>
            </FormGroupGrid>
        </GridForm>
    )
}