export const TruckDetectionStatus = {
	none: 0,
	no_active_orders: 1,
	active_orders: 2,
    no_orders: 3,
}

export const TruckDetectionStatusColors = {
	[TruckDetectionStatus.none]: "#FFFFFF", // white
    [TruckDetectionStatus.no_active_orders]: "#FFFF00", // yellow
    [TruckDetectionStatus.active_orders]: "#b7ff7d", // green
    [TruckDetectionStatus.no_orders]: "#ff8c8c", // red
}