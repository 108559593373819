import React from 'react';
import {
  Filter,
  DateInput,
  NumberInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput 
} from 'react-admin';
import {useTranslate} from 'ra-core';

import { NotifStatus } from '../../../entities/NotifStatus';
import { NotifType } from '../../../entities/NotifType';
import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { CompanySelect } from '../../../components/inputs';

const Filters = (props) => {
  const translate = useTranslate();
  return(
  <Filter {...props}>
    <SelectEnumInput
      source="status"
      key={"status"}
      enumName="status"
      resettable
      enumObj={NotifStatus}
    />
    <SelectEnumInput
      source="type"
      key={"notif.type"}
      enumName="notif.type"
      resettable
      enumObj={NotifType}
    />
    <DateInput
      label={translate("osfc.filters.dates.date_from")}
      source="time.time_from"
    />
    <DateInput
      label={translate("osfc.filters.dates.date_to")}
      source="time.time_to"

    />
    <DateInput
      label={translate("osfc.filters.dates.from_sent_date")}
      source="time_sent.sent_from"
    />
    <DateInput
      label={translate("osfc.filters.dates.to_sent_date")}
      source="time_sent.sent_to"
    />
    <NumberInput
      source="order_id"
      />
    <CompanySelect 
      source="customer_id" resettable
    />
    <CompanySelect source="eta_3rd_party" emptyValue={null} refer="transporters" emptyText="All Companies" defaultValue={null} 
							allowEmpty resettable key={"eta_3rd_party"} filter={{all_transporters: true}}  />
    <CompanySelect source="etd_3rd_party" emptyValue={null} refer="transporters" emptyText="All Companies" defaultValue={null} 
							allowEmpty resettable key={"etd_3rd_party"} filter={{all_transporters: true}}  />
    <ReferenceInput source="user_id" reference="users" label="Recipient name">
      <AutocompleteInput optionText={choice => choice && choice.first_name && choice.last_name ? `${choice.first_name} ${choice.last_name}` : ''} resettable />
		</ReferenceInput>

    {/* <TextInput 
      source="recipient_name"
    /> */}
  </Filter>
)};
export default Filters;