import React from 'react';
import {
    CreateButton,
    useListContext,
    sanitizeListRestProps,
    TopToolbar, ShowButton,
    EditButton, Link, Button,
} from 'react-admin';

import DescriptionIcon from '@material-ui/icons/Description';
import SubjectIcon from '@material-ui/icons/Subject';
import VisibilityIcon from '@material-ui/icons/Visibility';


export const TasksActions = (props) => {
    const { className, exporter, filters, ...rest } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        showFilter,
        selectedIds,
        total,
    } = useListContext(props);

    return React.useMemo(
        () => (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters &&
                    React.cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })}
                {hasCreate && <CreateButton basePath={basePath} />}
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    );
};


export const ActionButtonsField = ({ isSearchInDocs, resource, basePath, record, ...props }) => (
    <React.Fragment>
        {record.has_documents || record.has_out_documents ?
            <ShowButton
                label="" 
                style={{minWidth: 'auto', }} 
                to={`/${resource}/${record.id}/show/2`}
                icon={record.has_documents ? <DescriptionIcon/> : <SubjectIcon />}
                {...props}			
            /> :
            <EditButton 
                label="" 
                style={{ minWidth: 'auto' }} 
                resource={resource}
                basePath={basePath} 
                record={record} 
            />
        }
        {isSearchInDocs && 
            <Link to={`/${resource}/${record.id}/show`} target={"_blank"}>
                <Button 
                    style={{ minWidth: 'auto' }}
                >
                    <VisibilityIcon/>
                </Button>
            </Link>
        }
    </React.Fragment>
);
