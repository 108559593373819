import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  SingleFieldList,
  ChipField,
  ReferenceManyField,
} from 'react-admin';
import { TrucksTrafficFilters } from './ListFilters';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { DirectionType } from '../../../entities/DirectionType';
import { DateTimeField } from '../../../components/fields/DateFormatField';

import { TruckDetectionStatusColors } from '../../../entities/TruckDetectionStatus';

import { YesNoField } from '../../../components/fields/YesNoField';


const defaultSort = {
  field: 'event_date_time',
  order: 'DESC',
};

const TrucksTraffic = ({ permissions, ...props }) => {
  const truckRowStyle = (record, index) => ({
    backgroundColor: TruckDetectionStatusColors[record.detection_status]
  });

  return (
    <List {...props}
      exporter={false}
      bulkActionButtons={false}
      sort={defaultSort}
      filters={<TrucksTrafficFilters />}
    >
      <Datagrid rowStyle={truckRowStyle}>
        <TextField source="id" sortable={false}/>
        <ReferenceField source="terminal_id" reference="terminal" link={false} sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="vehicle_license_plate"sortable={false} />
        <SelectEnumField source="vehicle_direction_type"  enumName="vehicle_direction_type" enumObj={DirectionType} sortable={false}/>
        <DateTimeField source="event_date_time" />
        <TextField source="vehicle_country" sortable={false} />
        <TextField source="anpr_confidence_level" sortable={false} />
        <TextField source="camera_ip_address" sortable={false} />
        <TextField source="camera_id" sortable={false} />
        <YesNoField source="added_manual" sortable={false} />
        <ReferenceManyField reference="orders" source="vehicle_orders" target="selected_ids" sortable={false} allowEmpty={true}> 
          <SingleFieldList style={{ display: "block" }}>
            <ChipField source="reference" size="small" />
          </SingleFieldList>
        </ReferenceManyField>
      </Datagrid>
    </List>
  );
};

export default TrucksTraffic;