import { OrderService } from '../../entities/OrderService';
import { getNow, str2datetime } from '../../utils/datetime';
import { isEmpty } from '../../utils/validation/required';

const orderWarningsMessageByFieldName = {
    "pallets": "osfc.dialogs.order_warnings.content.pallets_empty",
    "kilos": "osfc.dialogs.order_warnings.content.kilos_empty",
    "eta_datetime": "osfc.dialogs.order_warnings.validate_order_by_eta_datetime",
    "etd_datetime": "osfc.dialogs.order_warnings.validate_order_by_etd_datetime"
};

export const orderWarningValidations = {
	"pallets": ((values, initialValues) => (isEmpty(values["pallets"]) && 
                [OrderService.reloadCarCar, OrderService.reloadCarTerminalCar].includes(values["service"]) ? 
                orderWarningsMessageByFieldName["pallets"] : null)),	
    "kilos": ((values, initialValues) => (isEmpty(values["kilos"]) && 
                [OrderService.intoPlukkStorage].includes(values["service"]) ? 
                orderWarningsMessageByFieldName["kilos"] : null)),    
    "eta_datetime": (values, initialValues) => validateETABackDating("eta", values, initialValues),
    "etd_datetime": (values, initialValues) => validateETABackDating("etd", values, initialValues)    
};


function validateETABackDating(prefix, values, initialValues) {
    const keys = [prefix+"_date", prefix+"_time", prefix+"_datetime"];
    const isCorrect = validateBackDating(getNow(2), initialValues[keys[0]], initialValues[keys[1]], values[keys[0]], values[keys[1]]);
    return isCorrect ? null : orderWarningsMessageByFieldName[keys[2]];
}

function validateBackDating(now, strDate1, strTime1, strDate2, strTime2) {
    if (strDate2) {
        const datetime2 = str2datetime(strDate2, strTime2);
        if (strDate1) {
            const datetime1 = str2datetime(strDate1, strTime1);
            if (datetime2 >= datetime1) return true;
        }
        return datetime2 >= now;
    }
    return true;
}