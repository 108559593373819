import React from 'react';

import { Filter, useTranslate } from 'react-admin';

import { ToggleTerminalsFilter } from '../../../components/filters/ToggleTerminalsFilter';
import { GroupingPeriodFilter } from './GroupingPeriodFilter';
import {DateInput} from '../../../components/inputs/DateTimeInput';


export const RevenueAndExpensesChartFilter = (props) => {
	const translate = useTranslate()

	return ( <Filter {...props}>
		<GroupingPeriodFilter source="grouping_period" defaultValue={null} alwaysOn key={"group_period"}/>
		<ToggleTerminalsFilter source="terminal_id" defaultValue={null} alwaysOn key={"terminal_id"}/>
		{[
			<DateInput 
				source="date_from"
				label={translate("osfc.filters.dates.date_from")}
				margin="dense"
				key={"date_from"}
				alwaysOn
			/>,
			<DateInput
				source="date_to"
				label={translate("osfc.filters.dates.date_to")}
				margin="dense"
				key={"date_to"}
				alwaysOn
			/>,
		 ]
		}
	</Filter>
)};
