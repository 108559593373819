import { useTranslate } from 'react-admin';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { DATE_FORMAT, format, parseISO } from '../../../components/_helpers/dateTime';
import { isNullUndEmpty }from "../../../utils/general"


const prepareDate = (datetime) =>{
    return format(parseISO(datetime), DATE_FORMAT);
}  


export const LaborCostDatagrid = ({laborCost}) => {
    const translate = useTranslate()

    return (
        <Table  size="small" aria-label="a dense table">
            <TableHead>
            <TableRow style={{backgroundColor:"#d8e6db"}}>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Cost type</TableCell>
                <TableCell align="left">Terminal account code</TableCell>
                <TableCell component="th" scope="row">Employee id</TableCell>
                <TableCell align="left">Hours</TableCell>
                <TableCell align="left">Cost</TableCell>
                <TableCell align="left">Total cost</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {laborCost.map((row) => (
                <TableRow key={row.name}>
                <TableCell component="th" scope="row">{prepareDate(row.date)}</TableCell>
                <TableCell component="th" scope="row">{row.cost_type}</TableCell>
                <TableCell component="th" scope="row">{row.terminal_account_code}</TableCell>
                <TableCell component="th" scope="row">{row.employee_id}</TableCell>
                <TableCell component="th" scope="row">{row.hours}</TableCell>
                <TableCell component="th" scope="row">{row.cost}</TableCell>
                <TableCell component="th" scope="row">{row.total_cost}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    )
}