import { MapperType } from "./MapperType"

export const mapperKeys = {
	[MapperType.loading]: ['desc', 'move_order', 'packaging', 'pallet_number', 'units', 'weight', 'box_weight', 'optional'],
	[MapperType.unloading]: ['desc', 'move_order', 'move_order_2', 'production', 'pallet_number', 'units', 'weight', 'box_weight', 'optional']
};

export const optionalMapperKeys = {
	[MapperType.loading]: ['desc', 'packaging', 'optional'],
	[MapperType.unloading]: ['optional'],
	[MapperType.none]: []
};
