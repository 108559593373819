import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslate, ReferenceField, TextField, FunctionField, NumberField, usePermissions } from 'react-admin';
import isEmpty from 'lodash/isEmpty';

import { DateTimeField } from '../../../../components/fields/DateFormatField';
import { PriorityField } from '../../../../components/fields/PriorityField';
import { YesNoField } from '../../../../components/fields/YesNoField';
import { NoneOrValueField } from '../../../../components/fields/NoOrValueField';
import { TextPopoverField } from '../../../../components/fields/TextPopoverField';
import { LongTextField } from '../../../../components/fields/LongTextField';
import { SelectTextField } from '../../../../components/fields/SelectTextField';

import { MyDatagrid } from './HistoryGrid';
import { TextPopover, EmptyInternalNotesPopover, InternalNotesPopover } from '../Pricelist/InternalNotesPopover';
import { AppManagerRoles, OrderServiceNames, UserRole } from '../../../../entities';

import { LabelSource } from '../../../../entities/LabelSource';
import { SelectEnumField } from '../../../../components/fields/SelectEnumField';
import { isInArray } from '../../../../utils/general';

const changedCellStyle = {
	boxShadow: '0px 0px 100px rgba(255,0,0,0.5) inset',
};


export const OrderHistoryGrid = ({nextForRecordId, ...props}) => {
    const translate = useTranslate();
    const statuses = useSelector(state => state.admin.resources['order-statuses'].data);

    const msgEmptyInternalNotes = translate("resources.order-history.massage.internal_notes_is_empty");

    const rowStyle = React.useCallback((record) => {
		return {
			backgroundColor: statuses[record.status] && statuses[record.status].color,
		};
	}, [ statuses ]);

    const cellStyle = (fieldName, record) => {
		if (!props.data || fieldName === "updated_at") return;

		const nextRecordId = nextForRecordId[record.id];
		if (!nextRecordId) return;
		if (props.data[nextRecordId][fieldName] !== record[fieldName]) return changedCellStyle;
    };
    const { permissions } = usePermissions();
    if (!permissions) return null;
    
    return (
        <MyDatagrid {...props} rowStyle={rowStyle} cellStyle={cellStyle}>
            {/* <ReferenceField sortable={false} label="resources.order-history.fields.updated_by" source="updated_by" reference="users" link={false}>
                <FunctionField render={record => permissions.role === UserRole.osfc_manager ? `${record.first_name} ${record.last_name}` : ""} />
            </ReferenceField> */}
            <TextField sortable={false} source="updated_by_name" label="resources.orders.fields.updated_by" />
            {/* <TextField sortable={false} source="rank_auto" label="resources.orders.fields.rank_auto" /> */}

            <DateTimeField sortable={false} source="updated_at" label="resources.orders.fields.updated_at" />

            <TextField sortable={false} source="reference" label="resources.orders.fields.reference" />
			<SelectEnumField sortable={false} source="service" enumObj={OrderServiceNames} reverse label="resources.orders.fields.service" />

            <ReferenceField sortable={false} source="terminal_id" label="resources.orders.fields.terminal" reference="terminal" link={false}>
                <TextField source="name" />
            </ReferenceField>

            <PriorityField sortable={false} source="priority" label="resources.orders.fields.priority" />

            {isInArray(AppManagerRoles, permissions.role) && <NumberField sortable={false} source="rank" label="resources.orders.fields.rank" />}
            <ReferenceField sortable={false} source="status" label="resources.orders.fields.status" reference="order-statuses" link={false}>
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField sortable={false} source="company_id" label="resources.orders.fields.company_id" reference="customer-companies" link={false}>
                <TextField source="full_name" />
            </ReferenceField>
            
            <ReferenceField sortable={false} source="cooler_type_id" label="resources.orders.fields.cooler_type_id" reference="cooler-types" link={false}>
                <TextField source="name" />
            </ReferenceField>

            <LongTextField source="place_from" label="resources.order-history.fields.place_from"/>
            <TextField sortable={false} source="eta_date" label="resources.orders.fields.eta_date" />
            <TextField sortable={false} source="eta_time" label="resources.orders.fields.eta_time" />
            <TextField sortable={false} source="eta_slot_time" label="resources.orders.fields.eta_slot_time" />
            <ReferenceField sortable={false} source="port_in" label="resources.orders.fields.port_in" reference="ports" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="resources.orders.fields.eta_3rd_party" sortable={false} source="eta_3rd_party" reference="customer-companies" link={false}>
                <TextField source="full_name"/>
            </ReferenceField>

            <SelectTextField sortable={false} source="eta_truck" refSource="eta_truck_id" reference="trucks" />
            <SelectTextField sortable={false} source="eta_trailer" refSource="eta_trailer_id" reference="trailers" />
            <SelectTextField sortable={false} source="eta_driver" refSource="eta_driver_id" reference="users" />
    
            <TextField sortable={false} source="eta_driver_phone" label="resources.order-history.fields.eta_driver_phone" />
            <YesNoField sortable={false} source="eta_show_doc_for_trans" label="resources.order-history.fields.eta_show_doc" />

            <NumberField sortable={false} source="boxes" label="resources.orders.fields.boxes" />
            <NumberField sortable={false} source="label" label="resources.orders.fields.label" />
            <SelectEnumField source="label_source" enumName="label_source" enumObj={LabelSource} />
            <NumberField sortable={false} source="pallets" label="resources.orders.fields.pallets" />
            <NumberField sortable={false} source="kilos" label="resources.orders.fields.kilos" />
            <YesNoField sortable={false} source="is_doc_for_nfsa" label="resources.orders.fields.is_doc_for_nfsa" />
            <TextField sortable={false} source="commodity" label="resources.orders.fields.commodity" />
            <YesNoField sortable={false} source="split_load" label="resources.orders.fields.split_load" />
            <TextField sortable={false} source="trip_number" label="resources.orders.fields.trip_number" />
            <NumberField sortable={false} source="loading_order" label="resources.orders.fields.loading_order" />

            <TextField sortable={false} source="etd_date" label="resources.orders.fields.etd_date" />
            <TextField sortable={false} source="etd_time" label="resources.orders.fields.etd_time" />
            <TextField sortable={false} source="etd_slot_time" label="resources.orders.fields.etd_slot_time" />
            <ReferenceField sortable={false} source="port_out" label="resources.orders.fields.port_out" reference="ports" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="resources.orders.fields.etd_3rd_party" sortable={false} source="etd_3rd_party" reference="customer-companies" link={false}>
                <TextField source="full_name"/>
            </ReferenceField>
            <LongTextField source="place_to"  label="resources.order-history.fields.place_to"/>

            <SelectTextField sortable={false} source="etd_truck" refSource="etd_truck_id" reference="trucks" />
            <SelectTextField sortable={false} source="etd_trailer" refSource="etd_trailer_id" reference="trailers" />
            <SelectTextField sortable={false} source="etd_driver" refSource="etd_driver_id" reference="users" />

            <TextField sortable={false} source="etd_driver_phone" label="resources.order-history.fields.etd_driver_phone" />
            <YesNoField sortable={false} source="etd_show_doc_for_trans" label="resources.order-history.fields.etd_show_doc" />

            <YesNoField sortable={false} source="plumb_seal" label="resources.orders.fields.plumb_seal" />
            <NoneOrValueField sortable={false} source="printed_doc" label="resources.orders.fields.printed_doc" />

            <TextField sortable={false} source="extra_loading_time" label="resources.orders.fields.extra_loading_time" />
            <YesNoField sortable={false} source="is_extra_loading_time_billable" label="resources.orders.fields.is_extra_loading_time_billable" />
            <YesNoField sortable={false} source="is_area_blocked" label="resources.orders.fields.is_area_blocked" />

            <ReferenceField sortable={false} source="area_id" label="resources.orders.fields.area_id" reference="areas" link={false}>
                <TextField source="name" />
            </ReferenceField>

            <YesNoField sortable={false} source="set_order_completed" label="resources.orders.fields.set_order_completed" />

            {/* <TextField source="notes" label="resources.orders.fields.notes" /> */}
            <TextPopoverField source="notes" label="resources.orders.fields.notes" />
            {/* <TextField source="internal_notes" label="resources.orders.fields.internal_notes" /> */}
            <TextPopoverField source="internal_notes" label="resources.orders.fields.internal_notes" />            
            {/* <FunctionField source="internal_notes" label="resources.orders.fields.internal_notes"
                render={record => isEmpty(record.internal_notes) ?
                    <EmptyInternalNotesPopover prev_notes={nextForRecordId[record.id] && props.data[nextForRecordId[record.id]]["internal_notes"]} msg={msgEmptyInternalNotes} /> :
                    <InternalNotesPopover notes={record.internal_notes} prev_notes={nextForRecordId[record.id] && props.data[nextForRecordId[record.id]]["internal_notes"]} />
                }
            /> */}
        </MyDatagrid>
    );
}