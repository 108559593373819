import React, { useMemo } from "react";

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrivedIcon from '@material-ui/icons/LocalShipping';

import { useSelector } from 'react-redux';
import {maxLength, NumberInput, ReferenceInput, SelectInput, TextInput, usePermissions, useDataProvider, useTranslate, translate, useSaveModifiers} from 'react-admin';
import {useForm, useFormState} from 'react-final-form';

import {required} from '../../../utils/validation/required';
import {requiredIfNoFieldValue} from '../../../utils/validation/requiredIfNoFieldValue';
import { validateArea } from "../../../utils/validation/order";
import {FormGroupGrid, GridForm, GridInput} from '../../../components/GridForm';
import {CompanySelect, CompanyText, DateInput, TimeInput, NumberInputEx} from '../../../components/inputs';
import { SelectUppercaseInput, SelectDisabledInput } from '../../../components/inputs/SelectUppercaseInput';
import {YesNoInput} from '../../../components/inputs/YesNoInput';
import {ReadOnlyInput} from '../../../components/inputs/TextReadonlyInput';
import { SelectTextInput } from '../../../components/inputs/SelectTextInput';
import { PhoneInput } from '../../../components/inputs';
import { formatPhoneNumber } from '../../../components/fields/PhoneField';

import {
	OrderService, OrderStatus, UserRole, 
	OrderStatusEditOrderTerminal, OrderStatusOnCompleteEdit, UserRoleByCompanyType,
	AppManagerRoles
} from '../../../entities';

import {TransporterInput} from './inputs/TransporterInput';
import {ServiceInput} from './inputs/ServiceInput';
import {CommodityInput} from './inputs/CommodityInput';
import {TripNumberInput} from './inputs/TripNumberInput';
import {LoadingOrderInput} from './inputs/LoadingOrderInput';
import {PrintedDocsInput} from "./inputs/PrintedDocsInput";

import {
	ArrivedPopup,
	DimensionsBlock,
	ETAETDFormGroup,
	etaEtdPorts,
	ResponsiveIconButton,
	useDriverChange,
	use3dPartyChange,
	useEtaEtdSync,
	driversSelector,
	DangerTextInputForInternalNotes,
} from './shared';
import { OSFCUserSelect } from '../../../components/inputs/OSFCUserSelect';
import { OrderReferenceInput } from '../../../components/inputs/OrderReferenceInput';

import {PortInput} from "../../../components/inputs/PortInput";
import { validatePlaceLength, validateRankField } from "../../../utils/validation/order";

import { LabelSource } from "../../../entities/LabelSource";
import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";
import { checkIsRankDisabled } from "../../../utils/checker/order";
import { isInArray } from "../../../utils/general";
import { rankChoicesGetter } from "../../../utils/getter";

const styleForButton = {
	'marginTop': '7vh',
};

export const ETAETDBlock = ({isAddMode, prefix, title, byTerminalFilter, ...props}) => {
	const { values } = useFormState();
	// const companyId = values.company_id || props.companyId;
	// const handleDriverChange = useDriverChange(prefix);
	const [handle3dPartyChangeOnTruck, handle3dPartyChangeOnTrailer] = use3dPartyChange(prefix);
	const handleEtaEtdSync = useEtaEtdSync(prefix);
	// const drivers = useSelector(driversSelector);

	const isDisabledForEditing = !!values[`${prefix}_3rd_party`];
	const isIntoPlukkStorage = values.service === OrderService.intoPlukkStorage;

	const dateRequiredValueKeyPrefix = prefix === 'eta' ? 'etd' : 'eta';
	const portKey = etaEtdPorts[prefix];

	// const currentDriver = drivers[values[`${prefix}_driver_id`]];
	const currentTransportFilter = {
		no_range: true,
		is_deleted: false,
		company_id: 0
	};
	const currentTransportSort = { field: "name", order: "ASC" };

	// const currentTransporter = values[`${prefix}_3rd_party`];
	const currentDriverFilter = {		
		no_range: true,
		is_blocked: false,
		is_deleted: false,
		company_id: 0
	};
	const currentDriverSort = { field: "first_name", order: "ASC" };

	let arrivedPopupProps = null;
	if (!isAddMode) {
		arrivedPopupProps = {
			...props,
			disabled: props.disabled || (props.enabledFields && (!props.enabledFields[props.source])),
			prefix,
			timeKey: `${prefix}_time`,
			slotTimeKey: `${prefix}_slot_time`,
			portKey,
			byTerminalFilter: byTerminalFilter
		};
		if (!arrivedPopupProps.disabled && (props.enabledFields && (!props.enabledFields[arrivedPopupProps.timeKey] || !props.enabledFields[arrivedPopupProps.slotTimeKey] || !props.enabledFields[arrivedPopupProps.portKey]))) {
			arrivedPopupProps.disabled = true;	
		}
	}

	// const isNot3rdPartyRole = UserRoleByCompanyType.customer_and_transporter_roles.indexOf(props.permissions.role) === -1

	const placePrefix = prefix === "eta" ? "from" : "to";

	const handle3dPartyChange = (e) => {
		handle3dPartyChangeOnTruck(e);
		handle3dPartyChangeOnTrailer(e);
	};

	return (
		<ETAETDFormGroup {...props} prefix={prefix}>
			<FormGroupGrid>
				<GridInput xs={6} sm={3} component={DateInput} source={`${prefix}_date`}
						   validate={[isIntoPlukkStorage ? requiredIfNoFieldValue(`${dateRequiredValueKeyPrefix}_date`) : required()]}
						   onChange={handleEtaEtdSync} />
				<GridInput xs={6} sm={3} component={TimeInput} source={`${prefix}_time`} />
				{!isAddMode && <Grid item xs={12} sm={1}>
					<ArrivedPopup {...arrivedPopupProps}
						render={({ openPopup }) => <ResponsiveIconButton
							onClick={openPopup}
							title={`${prefix.toUpperCase()} Arrived`}
							icon={<ArrivedIcon fontSize="small" />}
							disabled={arrivedPopupProps.disabled}
						/>}
					/>
				</Grid>}
				<GridInput xs={6} sm={3} component={TimeInput} source={`${prefix}_slot_time`} />
				<GridInput xs={6} sm={2} component={PortInput} source={portKey} 
						   hidden={isAddMode} filter={byTerminalFilter} />
			</FormGroupGrid>
			<FormGroupGrid>
				{/* <GridInput xs={12} sm={6} md={6} component={TransporterInput} source={`${prefix}_3rd_party`} prefix={prefix} disabled={!values.company_id} updateDependency={companyId} companyId={companyId} /> */}
				<GridInput xs={12} sm={5} md={5} component={TransporterInput} source={`${prefix}_3rd_party`} prefix={prefix} curr_company_id={values.company_id} disabled={!values.company_id} onChange={handle3dPartyChange} />
				{/* <SelectTextInput xs={12} sm={4} md={4} sourceText={`${prefix}_driver`} sourceSelect={`${prefix}_driver_id`} reference="drivers" 
								 optionText={record => `${record.first_name} ${record.last_name} | ${formatPhoneNumber(record.phone)}`} 
								 filter={companyId && currentDriverFilter} disabled={true}
								 validate={[maxLength(64)]} onChange={handleDriverChange} />  */}
				<GridInput  xs={12} sm={5} md={5} component={TextInput} source={`place_${placePrefix}`} validate={[validatePlaceLength]} />
				<GridInput xs={6} sm={2} md={2} component={YesNoInput} source={`${prefix}_show_doc_for_trans`}
					label={"resources.orders.fields." + prefix + "_show_doc"} defaultValue={true} disabled={!isDisabledForEditing}/>
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} sourceText={`${prefix}_driver`} sourceSelect={`${prefix}_driver_id`} reference="drivers" 
							optionText={record => `${record.first_name} ${record.last_name} | ${formatPhoneNumber(record.phone)}`}
							filter={currentDriverFilter} sort={currentDriverSort} disabled={isDisabledForEditing} />
				{/* <GridInput xs={12} sm={6} md={6} component={SelectTextInput} sourceText={`${prefix}_driver`} sourceSelect={`${prefix}_driver_id`} reference="drivers" 
								 filter={companyId && currentDriverFilter} disabled={true} /> */}
				<GridInput xs={12} sm={6} md={6} component={PhoneInput} source={`${prefix}_driver_phone`} disabled={isDisabledForEditing}/>
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} label={"resources.orders.fields." + prefix + "_truck"} 
								 sourceText={`${prefix}_truck`} sourceSelect={`${prefix}_truck_id`} reference="trucks" source3rdParty={`${prefix}_3rd_party`}
								 filter={currentTransportFilter} sort={currentTransportSort} disabled={isDisabledForEditing} validate={[maxLength(64)]} />
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} label={"resources.orders.fields." + prefix + "_trailer"} 
								 sourceText={`${prefix}_trailer`} sourceSelect={`${prefix}_trailer_id`} reference="trailers" source3rdParty={`${prefix}_3rd_party`}
								 filter={currentTransportFilter} sort={currentTransportSort} disabled={isDisabledForEditing} validate={[maxLength(64)]} />
			</FormGroupGrid>
		</ETAETDFormGroup>
	);
}

export const FormManager = ({ isAddMode, ...props }) => {
	const form = useForm();
	const { permissions } = usePermissions();
	const { values, initialValues } = useFormState();
    const dataProvider = useDataProvider();
	const translate = useTranslate();

	const onCompanyChange = val => {
		if (val){
			dataProvider.getOne("customer-companies", { id: val})
				.then((data) => {
					form.change("commodity", data.data.commodity);
					form.change("plumb_seal", data.data.plumb_seal);
					form.change("terminal_id", data.data.terminal_id);
					onTerminalChange(data.data.terminal_id);
					form.change("set_order_completed", data.data.set_order_completed);
					form.change("is_copy_documents", data.data.copy_docs_on_clone)
				})
		} else{
			form.change("commodity", 'salmon');
			// form.change("area_id", null);
			form.change("plumb_seal", false);
			form.change("terminal_id", null);
			onTerminalChange(null);
			form.change("set_order_completed", false);
			form.change("is_copy_documents", false)
		}		
	};

	const onTerminalChange = val => {
		form.change("port_in", null);
		form.change("port_out", null);
		form.change("area_id", null);
		form.change("is_area_blocked",  false);
	};

	React.useEffect(()=>{
        if (isAddMode && props.record && props.record.company_id){
            dataProvider.getOne("customer-companies", { id: props.record.company_id})
            .then((data) => {
                form.change("is_copy_documents", data.data.copy_docs_on_clone)
            });
        }
    }, [isAddMode, props.record]);

	const rankChoices = useMemo(() => rankChoicesGetter(props.record), [props.record]);

	const onIsDocForNfsaChange = React.useCallback(item => {
		const englishText = "\nDocuments MUST be obtained from the Norwegian Food Safety Authority";
		const norwegianText = "\nDokumenter MÅ hentes på Mattilsynet";
		const old_internal_notes_text = values.internal_notes ? values.internal_notes : ""
		const newValueInternalNotes = item.target.value ? old_internal_notes_text + englishText + norwegianText :
			old_internal_notes_text.replace(englishText + norwegianText, "");
		form.change("internal_notes", newValueInternalNotes);
	}, [values.internal_notes, form]);

	if (!permissions) return null;

	const canChangeCustomerAndReference = (
		isAddMode
		|| initialValues.status === OrderStatus.LOAD_IN_TERMINAL_STORAGE
		|| initialValues.status === OrderStatus.BOOKED
	);

	// let statusDisabled = false;
	let enabledFields;

	if (props.record.status === OrderStatus.ORDER_COMPLETED) {
		if (isInArray(AppManagerRoles, permissions.role)) enabledFields = {status: true};
		else enabledFields = {};
	}

	const isTerminalDisabled = values["status"] ? 
		!OrderStatusEditOrderTerminal.includes(values["status"]) : false;
	const isRankDisabled = checkIsRankDisabled(values);	

	const byTerminalFilter = { terminal_id: [values["terminal_id"]] };

	const handleLabelSourceChange = (event) =>{
		const val = event.target.value
		if(!val){
			form.change("label", null)
		} else if(val === LabelSource.pallets){
			form.change("label", values.pallets)
		} else if(val === LabelSource.boxes){
			form.change("label", values.boxes)
		}
	}

	// const statusDisabled = (permissions.role !== UserRole.osfc_manager) && (
	// 	(props.record.status === OrderStatus.ORDER_COMPLETED)
	// );
	// const enabledFields = initialValues.status === OrderStatus.ORDER_COMPLETED ? {status: true} : undefined
	
	const handleAreaChange = (event) => {
		if(event.target.value){
			if (values["service"] === OrderService.intoPlukkStorage && values["etd_date"]) {
				form.change("is_area_blocked",  true)
			}				
		} else{
			form.change("is_area_blocked",  false)
		}
	  };


	// const choices = props.record.rank ?  [{id: false, name: "NO (" +  props.record.rank + ")"}, {id: true, name: "YES (M)"}] : 
	// 									 [{id: false, name: "NO ()"}, {id: true, name: "YES (M)"}];
	  

	return (
		<GridForm {...props} enabledFields={enabledFields}>
			<FormGroupGrid>
				{canChangeCustomerAndReference ?
					<GridInput source="company_id" sm={2} component={CompanySelect} validate={[required()]} onChange={onCompanyChange} /> :
					<GridInput source="company_id" sm={2} component={CompanyText} disabled={true} />
				}
				<GridInput source="reference" sm={2} component={canChangeCustomerAndReference ? OrderReferenceInput : ReadOnlyInput} disabled={!canChangeCustomerAndReference} validate={[required()]} />
				<GridInput source="service" sm={2} component={ServiceInput} validate={[required()]} defaultValue={OrderService.reloadCarCar} />
				<GridInput sm={2} hidden={isAddMode} source="status" reference="order-statuses">
					<ReferenceInput>
						{enabledFields && enabledFields["status"] ? <SelectDisabledInput allowedIds={OrderStatusOnCompleteEdit} optionText="name" /> : <SelectUppercaseInput optionText="name" />}
						{/* <SelectUppercaseInput optionText="name" /> */}
					</ReferenceInput>
				</GridInput>
				<GridInput sm={1} component={YesNoInput} source="priority" defaultValue={false} />
				{/* <GridInput sm={1} hidden={isAddMode} component={YesNoInput} label={translate("resources.orders.fields.rank.edit.label")}
						   source="rank" disabled={isRankDisabled}
						//    validate={validateRankField}
						   format={val => val === 'M' ? true : val===null || val===undefined ? false : typeof val === 'string' ? false : true }
				 		   parse={val => val === true ? 0 : null}
						   choices={choices}/> */}
				<GridInput sm={1} hidden={isAddMode} component={YesNoInput} source="rank" disabled={isRankDisabled} choices={rankChoices} />

				<GridInput sm={1} component={YesNoInput} source="set_order_completed" defaultValue={false} />
				<GridInput sm={1}>
					<ReferenceInput source="terminal_id" reference="terminal" disabled={isTerminalDisabled} validate={[required()]} onChange={onTerminalChange}>
						<SelectInput optionText="name" />
					</ReferenceInput>
				</GridInput>
				{/* <GridInput sm={2} component={LoadNoInput} source="load_no" /> */}
			</FormGroupGrid>

			{/* <Grid item sm={12} md={12} lg={6}> */}
				<ETAETDBlock sm={12} md={12} lg={6} isAddMode={isAddMode} prefix="eta" title="ETA  —  ARRIVAL" 
					permissions={permissions} {...props} enabledFields={enabledFields} byTerminalFilter={byTerminalFilter} />
			{/* </Grid> */}
			{/* <Grid item sm={12} md={12} lg={6}> */}
				<ETAETDBlock sm={12} md={12} lg={6} isAddMode={isAddMode} prefix="etd" title="ETD  —  DEPARTURE" 
					permissions={permissions} {...props} enabledFields={enabledFields} byTerminalFilter={byTerminalFilter} />
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={3}> */}
				<FormGroupGrid xs={12} md={6} lg={3} title={translate("resources.orders.block-label.split-loads")}>
					<GridInput xs={4} component={YesNoInput} source="split_load" defaultValue={false} />
					<GridInput xs={4} component={TripNumberInput} source="trip_number" />
					<GridInput xs={4} component={LoadingOrderInput} source="loading_order" />
				</FormGroupGrid>
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={3}> */}
				<FormGroupGrid xs={12} md={6} lg={3} title={translate("resources.orders.block-label.plumb-seal")}>
					<GridInput xs={6} sm={4} component={YesNoInput} source="plumb_seal" defaultValue={false} />
					<GridInput xs={6} sm={8} component={TextInput} source="plumb_number" />
				</FormGroupGrid>
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={3}> */}
				<FormGroupGrid  xs={12} md={6} lg={3} title={translate("resources.orders.block-label.extra-loading-time")}>
					<GridInput xs={6} sm={5} component={NumberInput} options={{ format: "" }} source="extra_loading_time" />
					<GridInput xs={6} sm={5} component={YesNoInput} source="is_extra_loading_time_billable" defaultValue={false} />
				</FormGroupGrid>
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={3}> */}
				<FormGroupGrid xs={12} md={6} lg={3} title={translate("resources.orders.block-label.storage-allocation")}>
					<GridInput xs={8} sm={8}>
						<ReferenceInput source="area_id" reference="areas" 
										filter={{ ...byTerminalFilter, disabled: false }} onChange={handleAreaChange} allowEmpty validate={validateArea}>
							<SelectInput optionText="name" disableValue="disabled_by_any" />
						</ReferenceInput>
					</GridInput>
					<GridInput xs={4} sm={4} component={YesNoInput} source="is_area_blocked" disabled={!values.area_id}/>
					<GridInput xs={6} sm={6} component={SelectEnumInput} onChange={handleLabelSourceChange} options={{ format: "" }} source="label_source" key={"label_source"} enumName="label_source" enumObj={LabelSource}/>
					<GridInput xs={6} sm={6} component={NumberInputEx} options={{ format: "" }} source="label" disabled={!values["label_source"]} />
				</FormGroupGrid>
			{/* </Grid> */}

			<FormGroupGrid>
				<GridInput xs={12} sm={6} md={3} hidden={values.service === OrderService.reloadCarCar}>
					<ReferenceInput source="cooler_type_id" reference="cooler-types" filter={{ order_service: values.service }}>
						<SelectUppercaseInput optionText="name" />
					</ReferenceInput>
				</GridInput>
				<GridInput xs={6} sm={6} md={3} component={PrintedDocsInput} source="printed_doc" defaultValue={false} />
				<GridInput xs={6} sm={6} md={3} component={CommodityInput} source="commodity" />
				<GridInput xs={6} sm={6} md={3} component={YesNoInput} source="is_doc_for_nfsa" defaultValue={false}
					onChange={onIsDocForNfsaChange}
				/>
			</FormGroupGrid>

			{/* <DimensionsBlock {...props} /> */}
			<FormGroupGrid xs={12} lg={6}>
				<GridInput xs={4} component={NumberInputEx} source="pallets" min={0} max={100} step={10} />
				<GridInput xs={4} component={NumberInputEx} source="boxes" min={0} max={100} step={5} />
				<GridInput xs={4} component={NumberInputEx} type="float" digitsCount={2} source="kilos" min={0} max={1000} step={100} />
			</FormGroupGrid>

			<FormGroupGrid lg={6}>
				<GridInput source="assignee_1" sm={6} md={3} lg={6} component={OSFCUserSelect} />
				<GridInput source="assignee_2" sm={6} md={3} lg={6} component={OSFCUserSelect} />
			</FormGroupGrid>

			<FormGroupGrid>
				<GridInput sm={12} md={6} component={TextInput} source="notes" multiline rows={6} validate={[maxLength(1024)]} />
				<GridInput sm={12} md={6} className="red-border" component={DangerTextInputForInternalNotes} source="internal_notes"
						   multiline rows={6} validate={[maxLength(1000)]} />
			</FormGroupGrid>
		</GridForm>
	);
};
