import { useTranslate } from 'react-admin';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { DATE_TIME_FORMAT, DATE_FORMAT, format, parseISO } from '../../../components/_helpers/dateTime';

import Link from '@material-ui/core/Link';

const prepareDatetime = (datetime) =>{
    return format(parseISO(datetime), DATE_TIME_FORMAT);
} 
const prepareDate = (datetime) =>{
    return format(parseISO(datetime), DATE_FORMAT);
}   

export const OrdersDatagrid = ({orders}) => {
    const translate = useTranslate()

    return (
        <Table  size="small" aria-label="a dense table">
            <TableHead>
            <TableRow style={{backgroundColor:"#d8e6db"}}>
                <TableCell align="left">Order id</TableCell>
                <TableCell align="left">ETA date</TableCell>
                <TableCell align="left">ETD date</TableCell>
                <TableCell align="left">Reference</TableCell>
                <TableCell align="left">Terminal id</TableCell>
                <TableCell align="left">Revenue</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {orders.map((row) => (
                <TableRow key={row.name}>
                <TableCell component="th" scope="row">{row.order_id}</TableCell>
                <TableCell component="th" scope="row">{row.eta_date}</TableCell>
                <TableCell component="th" scope="row">{row.etd_date}</TableCell>
                <TableCell component="th" scope="row">
                    <Link href={`#/orders/${row.order_id}/show`} target="_blank">{row.reference}</Link>
                </TableCell>
                <TableCell component="th" scope="row">{row.terminal_id}</TableCell>
                <TableCell component="th" scope="row">{row.order_cost}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    )
}